// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // DEV
  // basicAPI: 'http://ec2-44-236-169-121.us-west-2.compute.amazonaws.com:3003/api/v1/admin/',
  // identityPoolId: 'us-west-2:38e2c08b-bbc6-40ce-8b93-989676c2abc2',
  // region: 'us-west-2',
  // bucketName: 'mindmaster-media-dev'

  //Test
  basicAPI: 'https://stage-admin-api.mindmaster.app/api/v1/admin/',
  //basicAPI: 'http://44.224.30.17:3001/api/v1/admin/',
  identityPoolId: 'us-west-2:38e2c08b-bbc6-40ce-8b93-989676c2abc2',
  region: 'us-west-2',
  bucketName: 'mindmaster-media-stage',

  // Production
  // basicAPI: 'https://admin-api.mindmaster.app/api/v1/admin/',
  // identityPoolId: 'us-west-2:38e2c08b-bbc6-40ce-8b93-989676c2abc2',
  // region: 'us-west-2',
  // bucketName: 'mindmaster-media-production',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
